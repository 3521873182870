<template>
  <div
    class="w-full"
    v-for="(availableAnswer, index) in availableAnswers"
    :key="index"
  >
    <button
      @click="answerAndProceed(questionGuid, availableAnswer)"
      class="flex justify-between items-center px-4 py-3 my-2 rounded-md border shadow-sm bg-white w-full"
      :class="
        answer === availableAnswer
          ? 'border-primary cursor-default'
          : 'hover:bg-gray-100'
      "
    >
      <div>{{ availableAnswer }}</div>
      <div
        class="flex justify-center items-center w-6 h-6 border rounded-full"
        :class="
          answer === availableAnswer
            ? 'border-primary bg-primary-light text-primary'
            : 'border-gray-400'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="ai ai-Check"
          v-if="answer === availableAnswer"
        >
          <path d="M4 12l6 6L20 6" />
        </svg>
      </div>
    </button>
  </div>
</template>

<script>
import useQuickscreen from "../../../composables/useQuickscreen";
export default {
  props: {
    questionGuid: {
      type: String,
      required: true,
      default: "",
    },
    availableAnswers: {
      type: Array,
      required: true,
      default: () => [],
    },
    answer: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup() {
    const { answerAndProceed } = useQuickscreen();

    return {
      answerAndProceed,
    };
  },
};
</script>

<style></style>
